import React from 'react';
import { Absolute, Box, Spinner, Stack, Text, BoxProps } from 'pws-design-system/design-system';

interface ActivityIndicatorProps extends BoxProps {
    size?: 'sm' | 'md' | 'lg' | 'xl';
    message?: string;
}

const ActivityIndicator = ({ size = 'xl', message, ...rest }: ActivityIndicatorProps) => (
    <Box width="full" minHeight="200px" position="relative" {...rest}>
        <Absolute anchor="center">
            <Stack align="center">
                <Spinner size={size} />
                {message && <Text mt={2} variant="label" fontSize="xs" whiteSpace="nowrap">{message}</Text>}
            </Stack>
        </Absolute>
    </Box>
);

export default ActivityIndicator;
