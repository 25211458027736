import React, { ReactNode, useState } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import {
    BlockLink, Box, Stack, Icon, Link, Logo, Text, BoxProps,
} from 'pws-design-system/design-system';
import NavigationStack from '../ui/NavigationStack';
import { AppContext } from '../../context/auth';
import useApi, { Api } from '../hooks/api/useApi';

const NavigationLabel = (props: any) => (
    <Text mt="3px" mb="-9px" fontFamily="heading" fontSize="3xl" fontWeight="bold" {...props} />
);

interface NavigationOverlayProps extends BoxProps {
    selectedNavigationIndex?: number;
    children?: ReactNode | Array<ReactNode>;
}

const NavigationOverlay = ({
    selectedNavigationIndex = 0,
    children,
    ...rest
}: NavigationOverlayProps) => {
    const [selectedIndex, setSelectedIndex] = useState(selectedNavigationIndex);
    const { request: logout } = useApi((api: Api) => api.routes.auth.logout());

    const handleLogout = () => {
        logout();
    };

    return (
        <AppContext.Consumer>
            {(session) => (
                <Stack p={4} bg="bg.base.primary" minHeight="100vh" justify="space-between" {...rest}>
                    <Box>
                        <BlockLink href={`${process.env.GATSBY_PWS_WEBSITE_URL}`}>
                            <Logo variantColor="light" height="50px" mt="30px" mb="50px" mx="auto" />
                        </BlockLink>
                        {session.user && session.user.isAdmin && (
                            <Box ml={3} p="3px" bg="brand.lightBlue.base" color="brand.darkBlue.base" rounded="sm" width="52px">
                                <Text fontSize="12px" fontWeight="bold" textTransform="uppercase" letterSpacing="wide" textAlign="center">Admin</Text>
                            </Box>
                        )}
                        <NavigationStack
                            mt={3}
                            spacing={4}
                            variant="light"
                            selectedIndex={selectedIndex}
                            itemOpts={{
                                fontSize: 'xl',
                            }}
                            onChange={(index) => setSelectedIndex(index)}
                        >
                            <BlockLink as={GatsbyLink} to="/stations">
                                <NavigationLabel>Stations</NavigationLabel>
                            </BlockLink>
                            {session.user && session.user.isAdmin && (
                                <BlockLink as={GatsbyLink} to="/users">
                                    <NavigationLabel>Users</NavigationLabel>
                                </BlockLink>
                            )}
                            <BlockLink as={GatsbyLink} to="/account">
                                <NavigationLabel>Account</NavigationLabel>
                            </BlockLink>
                        </NavigationStack>
                    </Box>
                    <Box ml={3}>
                        <GatsbyLink to="/login" onClick={handleLogout}>
                            <Text variant="title1">Logout</Text>
                        </GatsbyLink>
                        <Stack mt={3} align="flex-start" isInline>
                            <Link href="https://www.pwsweather.com/support">
                                <Text variant="title1">Support</Text>
                            </Link>
                            <Icon name="chat" mt="3px" ml={2} color="bg.500" />
                        </Stack>
                        <Stack mt={4}>
                            <Text variant="footnote" color="bg.500" textAlign="center">
                                Copyright 2020 AerisWeather
                            </Text>
                            <Text variant="caption2" color="bg.500" textAlign="center" fontWeight="bold">
                                <Link href="https://www.pwsweather.com/support">Terms of Use</Link>
                                <Link ml={3} href="https://www.pwsweather.com/support">Privacy Policy</Link>
                            </Text>
                        </Stack>
                    </Box>
                </Stack>
            )}
        </AppContext.Consumer>
    );
};

export default NavigationOverlay;
