import { Link as GatsbyLink } from 'gatsby';
import { BlockLink, Box, BoxProps, Icon, Link, Logo, SidebarNavigation, Stack, Text } from 'pws-design-system/design-system';
import { SidebarNavigationItem } from 'pws-design-system/design-system/components/menu';
import React, { ReactNode, useState } from 'react';
import { AppContext } from '../../context/auth';
import useApi, { Api } from '../hooks/api/useApi';

interface SidebarProps extends BoxProps {
    selectedNavigationIndex?: number;
    children?: ReactNode | Array<ReactNode>;
}

const Sidebar = ({
    selectedNavigationIndex = 0,
    children,
    ...rest
}: SidebarProps) => {
    const [selectedIndex, setSelectedIndex] = useState(selectedNavigationIndex);
    const { request: logout } = useApi((api: Api) => api.routes.auth.logout());

    const handleLogout = () => {
        logout();
    };

    return (
        <AppContext.Consumer>
            {(session) => (
                <Stack p={[3, null, null, 4]} justify="space-between" height="full" {...rest}>
                    <Box>
                        <BlockLink href={`${process.env.GATSBY_PWS_WEBSITE_URL}`}>
                            <Logo variantColor="light" height="40px" mt="30px" mx="auto" />
                        </BlockLink>
                        <Box mt={['50px', null, null, '80px']} />
                        {session.user && session.user.isAdmin && (
                            <Box
                                ml={3}
                                p="3px"
                                bg="brand.lightBlue.base"
                                color="brand.darkBlue.base"
                                rounded="sm"
                                width="52px"
                            >
                                <Text
                                    fontSize="12px"
                                    fontWeight="bold"
                                    textTransform="uppercase"
                                    letterSpacing="wide"
                                    textAlign="center"
                                >
                                    Admin
                                </Text>
                            </Box>
                        )}
                        <SidebarNavigation
                            mt={3}
                            variant="light"
                            selectedIndex={selectedIndex}
                            onChange={(index) => setSelectedIndex(index)}
                        >
                            <SidebarNavigationItem
                                as={GatsbyLink}
                                title="Stations"
                                to="/stations"
                                pt={2}
                            />
                            {session.user && session.user.isAdmin && (
                                <SidebarNavigationItem
                                    as={GatsbyLink}
                                    title="Users"
                                    to="/users"
                                    pt={2}
                                />
                            )}
                            <SidebarNavigationItem
                                as={GatsbyLink}
                                title="Account"
                                to="/account"
                                pt={2}
                            />
                        </SidebarNavigation>
                    </Box>
                    <Box ml={3}>
                        <Stack spacing={3}>
                            <Link href={`${process.env.GATSBY_PWS_WEBSITE_URL}`}>
                                <Text variant="title2">PWSWeather Home</Text>
                            </Link>
                            <GatsbyLink to="/login" onClick={handleLogout}>
                                <Text variant="title2">Logout</Text>
                            </GatsbyLink>
                            <Stack mt={3} align="flex-start" isInline>
                                <Link href="https://www.pwsweather.com/support">
                                    <Text variant="title2">Support</Text>
                                </Link>
                                <Icon name="chat" mt="3px" ml={2} color="bg.500" />
                            </Stack>
                        </Stack>
                        <Box mt={4}>
                            <Text variant="footnote" color="bg.500">
                                <Link href="https://www.pwsweather.com/terms-and-conditions">
                                    Terms
                                </Link>
                                {' | '}
                                <Link href="https://www.pwsweather.com/privacy-policy">
                                    Privacy
                                </Link>
                            </Text>
                        </Box>
                    </Box>
                </Stack>
            )}
        </AppContext.Consumer>
    );
};

export default Sidebar;
