import React from 'react';
import { Box, Heading, Hide, Stack } from 'pws-design-system/design-system';
import ErrorBoundary from '../ErrorBoundary';

interface ContentPanelLayoutProps {
    title: string;
    category?: string;
    leftElement?: any;
    rightElement?: any;
    toolbarElement?: any;
    toolbarPlacement?: 'top' | 'bottom';
    showKeyline?: boolean;
    children?: any;
}

const ContentPanelLayout = ({
    title,
    category,
    leftElement,
    rightElement,
    toolbarElement,
    toolbarPlacement = 'top',
    showKeyline = false,
    children,
    ...rest
}: ContentPanelLayoutProps) => (
    <Box px={[3, 4, null, 5]} py={[3, 4, null, 5]} {...rest}>
        <ErrorBoundary>
            <Stack justify="space-between" align="center" mb={showKeyline ? 0 : 4} isInline>
                <Hide mobile>
                    <Stack align="center" spacing={3} isInline>
                        {leftElement}
                        <Box>
                            {category && <Heading as="h3" variant="label" fontSize="xs" mb={2} color="text.base.tertiary">{category}</Heading>}
                            <Heading as="h1" variant="hero" isTruncated>{title}</Heading>
                        </Box>
                        {rightElement}
                    </Stack>
                </Hide>
                <Hide tablet desktop>
                    <Stack spacing={1}>
                        <Box maxWidth="100%">
                            {category && <Heading as="h3" variant="label" fontSize="xs" mb={2} color="text.base.tertiary">{category}</Heading>}
                            <Heading as="h1" variant="hero" isTruncated>{title}</Heading>
                        </Box>
                        {rightElement}
                    </Stack>
                </Hide>
                {(toolbarElement && toolbarPlacement === 'top') && <>{toolbarElement}</>}
            </Stack>
            {showKeyline && <Box height="2px" mt={2} mb={[3, null, null, 4]} bg="bg.200" />}
            {children}
            {(toolbarElement && toolbarPlacement === 'bottom') && <>{toolbarElement}</>}
        </ErrorBoundary>
    </Box>
);

export default ContentPanelLayout;
