import React, { useState, useEffect, cloneElement } from 'react';
import { Box, Stack, BoxProps } from 'pws-design-system/design-system';

// @ts-ignore error 2430
export interface NavigationStackOpts extends BoxProps {
    variant?: 'light' | 'dark'
    selectedIndex?: number
    itemOpts?: any
    selectedItemOpts?: any
    indicatorSize?: number
    indicatorColor?: string
    children?: any
    onChange?: (index: number) => void
}

const NavigationStack: React.FC<NavigationStackOpts> = ({
    variant = 'dark',
    selectedIndex: initialSelectedIndex = 0,
    itemOpts,
    selectedItemOpts,
    indicatorSize = 6,
    indicatorColor = 'brand.green.base',
    onChange,
    children,
    ...rest
}: NavigationStackOpts): React.ReactElement => {
    const [selectedIndex, setSelectedIndex] = useState(initialSelectedIndex);

    const colorKey = (variant === 'light') ? 'light' : 'dark';
    const navItemOpts = {
        color: `text.${colorKey}.tertiary`,
        font: 'heading',
        fontSize: '3xl',
        fontWeight: 'bold',
        pl: '4px',
        textDecoration: 'none',
        _hover: {
            color: `text.${colorKey}.primary`,
            textDecoration: 'none',
        },
        ...itemOpts,
    };
    const selectedNavItemOpts = {
        color: `text.${colorKey}.primary`,
        ...selectedItemOpts,
    };

    const styledItems = React.Children.map(children, (child: any, index) => {
        if (child) {
            let childProps: any = { ...child.props } || {};
            childProps.my = 0;
            childProps.onClick = () => setSelectedIndex(index);

            if (index === selectedIndex) {
                childProps = { ...childProps, ...selectedNavItemOpts };
            }

            return cloneElement(child, { ...navItemOpts, ...childProps });
        }
        return child;
    });

    useEffect(() => {
        if (onChange) {
            onChange(selectedIndex);
        }
    }, [selectedIndex]);

    return (
        <Stack spacing={3} {...rest}>
            {styledItems.map((item: any, index: number) => (
                <Box key={`item-${index}`} position="relative">
                    <Box position="absolute" top="0" bottom="0" overflow="hidden">
                        <Box
                            width={(index === selectedIndex) ? `${indicatorSize}px` : 0}
                            height="100%"
                            bg={indicatorColor}
                        />
                    </Box>
                    <Box ml="12px">
                        {item}
                    </Box>
                </Box>
            ))}
        </Stack>
    );
};

export default NavigationStack;
