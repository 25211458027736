import React, { ErrorInfo } from 'react';
import { Box, Button, Text, Link, Stack } from 'pws-design-system/design-system';
import Sentry from '../error/Sentry';

const ERROR_REPORTING_ENABLED = process.env.GATSBY_ERROR_REPORT_ENABLE;

interface ErrorBoundaryProps {
    children?: any;
}

interface ErrorBoundaryState {
    hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {

    constructor(props: ErrorBoundaryProps) {
        super(props);

        this.state = {
            hasError: false,
        };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error: Error, info: ErrorInfo) {
        if (Boolean(ERROR_REPORTING_ENABLED) !== true) {
            return;
        }
        console.error(error); // eslint-disable-line no-console
        Sentry.withScope((scope) => {
            Sentry.captureException(error);
            scope.setExtras(info);
            this.setState({ hasError: true });
        });
    }

    render() {
        const { children } = this.props;
        const { hasError } = this.state;

        if (hasError === true) {
            return (
                <Box>
                    <Text variant="subheadline">
                        We encountered an error displaying this content.
                    </Text>
                    <Text variant="title2">
                        Our team has been notified about this error.
                        <Link href="https://www.pwsweather.com/support" target="_blank">Contact our support team</Link>{' '}
                        if you’d like to provide additional information regarding the error.
                    </Text>
                    <Stack isInline>
                        <Button onClick={() => window.location.reload()}>Reload Page</Button>
                    </Stack>
                </Box>
            );
        }
        return (<>{children}</>);
    }

}

export default ErrorBoundary;
