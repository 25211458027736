import React, { useState, ReactNode } from 'react';
import { motion, useViewportScroll } from 'framer-motion';
import {
    Absolute, Box, Button, Fixed, Flex, Hide, Icon, IconButton, Layer, Surface, Text,
} from 'pws-design-system/design-system';
import Sidebar from '../navigation/Sidebar';
import NavigationOverlay from '../navigation/NavigationOverlay';
import useScrollPosition from '../hooks/useScrollPosition';

const getNavigationIndex = (info: any = {}): number => {
    const { pathname } = info;
    // console.log(pathname);
    if (/\/users?/.test(pathname)) {
        return 1;
    }
    if (/\/account/.test(pathname)) {
        return 2;
    }
    return 0;
};

const MotionFixed = motion.custom(Fixed);

interface DashboardLayoutProps {
    title?: string;
    children: ReactNode | Array<ReactNode>;
    location?: any;
    onGoBack?: () => void;
}

const DashboardLayout = ({
    title,
    children,
    location,
    onGoBack,
}: DashboardLayoutProps) => {
    const [show, setShow] = useState(false);
    const scroll = useScrollPosition();
    return (
        <Flex height={['auto', null, null, '100vh']}>
            <Hide mobile>
                <Sidebar width={['250px', null, null, '300px']} selectedNavigationIndex={getNavigationIndex(location)} />
            </Hide>
            <Hide tablet desktop>
                <MotionFixed
                    top="0"
                    left="0"
                    right="0"
                    zIndex={499}
                    bg="bg.100"
                    height="55px"
                    opacity={scroll.y >= 60 ? 1 : 0}
                    animate={{
                        opacity: scroll.y >= 60 ? 1 : 0,
                    }}
                    transition={{
                        duration: 0.2,
                    }}
                >
                    {title && (
                        <Text
                            color="text.base.primary"
                            textTransform="uppercase"
                            letterSpacing="widest"
                            textAlign="center"
                            fontSize="sm"
                            fontWeight="bold"
                            lineHeight="55px"
                            isTruncated
                        >
                            {title}
                        </Text>
                    )}
                </MotionFixed>
                <Fixed top="0" left="0" right="0" zIndex={500} height="55px">
                    {onGoBack && (
                        <Absolute top="8px" left="10px" zIndex={2}>
                            <Button
                                ml="-5px"
                                variant="ghost"
                                variantColor="light"
                                size="sm"
                                width="40px"
                                height="40px"
                                aria-label="Go back"
                                onClick={onGoBack}
                            >
                                <Icon name="arrow-left" width="20px" height="20px" />
                            </Button>
                        </Absolute>
                    )}
                    <Absolute top="10px" right="10px" zIndex={2}>
                        <IconButton
                            mr="-5px"
                            color="light"
                            size="md"
                            variant="ghost"
                            width="40px"
                            height="40px"
                            icon="menu"
                            aria-label="Site Navigation"
                            onClick={() => setShow(true)}
                        />
                    </Absolute>
                </Fixed>
                <Layer
                    animation="slide"
                    animationDirection="right"
                    isActive={show}
                    onClose={() => setShow(false)}
                    zIndex={50000}
                    fill
                >
                    <Absolute top="10px" right="10px">
                        <IconButton icon="close" aria-label="Close" bg="transparent" _hover={{ background: 'transparent' }} onClick={() => setShow(false)} />
                    </Absolute>
                    <NavigationOverlay />
                </Layer>
            </Hide>
            <Surface elevation={1} total={3} overflowY={[null, null, null, 'scroll']} flexGrow={1} zIndex={1} minHeight="100vh">
                <Hide tablet desktop>
                    <Box height="45px" />
                </Hide>
                {children}
            </Surface>
        </Flex>
    );
};

export default DashboardLayout;
