import React from 'react';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import { AppContext } from '../context/auth';
import Layout from './layouts/Layout';
import ActivityIndicator from './ui/ActivityIndicator';

const ADMIN_ROUTES = ['^/users', '^/station/[^\\/]+/admin'];

const UnauthenticatedContent = ({ isAuthenticating = false }: any) => (
    isAuthenticating ? (
        <ActivityIndicator />
    ) : (
        <></>
    )
);

interface ProtectedResourceProps {
    children?: any;
}

const ProtectedResource = ({
    children,
}: ProtectedResourceProps) => {
    const location = useLocation();

    const getContent = (session: any) => {
        if (session.failedAuthentication) {
            navigate('/login', { state: { referrer: location } });
            return null;
        }
        if (session.isAuthenticated) {
            if (session.user.isAdmin === false && location && location.pathname) {
                let redirect = false;
                // redirect if an admin route and authenticated user is not an admin
                ADMIN_ROUTES.forEach((route) => {
                    const regex = new RegExp(route);
                    if (location.pathname.match(regex)) {
                        redirect = true;
                    }
                });
                if (redirect) {
                    navigate('/');
                    return null;
                }
            }
            return children;
        }
        return <UnauthenticatedContent isAuthenticating={session.isAuthenticating} />;
    };
    return (
        <Layout>
            <AppContext.Consumer>
                {(session) => getContent(session)}
            </AppContext.Consumer>
        </Layout>
    );
};

export default ProtectedResource;
