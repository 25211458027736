import { useState, useRef, useLayoutEffect, useEffect } from 'react';

const isBrowser = typeof window !== 'undefined';

type ScrollPosition = {
    x: number;
    y: number;
};

interface HookOpts {
    element?: any;
    useWindow?: boolean;
    wait?: number;
}

const getScrollPosition = ({
    element,
    useWindow,
}: HookOpts): ScrollPosition => {
    if (!isBrowser) return { x: 0, y: 0 };

    const target = element ? element.current : document.body;
    const position = target.getBoundingClientRect();

    return useWindow ? {
        x: window.scrollX, y: window.scrollY,
    } : {
        x: position.left, y: position.top,
    };
};

const useScrollPosition = ({
    element,
    useWindow = true,
    wait = 100,
}: HookOpts = {}): ScrollPosition => {
    const [position, setPosition] = useState(getScrollPosition({ useWindow }));
    const pos = useRef(getScrollPosition({ useWindow }));
    let throttleTimeout: any = null;

    const callback = () => {
        const currentPos = getScrollPosition({ element, useWindow });
        pos.current = currentPos;
        setPosition(currentPos);
        throttleTimeout = null;
    };

    const layoutEffect = isBrowser ? useLayoutEffect : useEffect;
    layoutEffect(() => {
        if (!isBrowser) return null;

        const handleScroll = () => {
            if (wait) {
                if (throttleTimeout === null) {
                    throttleTimeout = setTimeout(callback, wait);
                }
            } else {
                callback();
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            if (throttleTimeout) {
                clearTimeout(throttleTimeout);
            }
        };
    }, []);

    return position;
};

export default useScrollPosition;
